import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useForm } from "react-hook-form";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Navigate, useNavigate  } from 'react-router-dom';
import FormInputDate from './form-components/FormInputDatepicker';
import {connect ,useDispatch} from "react-redux";
import { getTable, getUserApproval } from './actions/auth';
import axios from "axios";
import Badge from '@mui/material/Badge';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';

const drawerWidth = 240;
const navItems = ['Home', 'About', 'Contact'];


function HomeLanderPage(props) {
  let API_URL = 'http://localhost:3004'
  const navigate = useNavigate();
  
  const user = props.user && props.user[0].username
  const dispatch = useDispatch();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [NotificationData, setNotificationData] = React.useState([]);
  
  
  const userDetails = sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user'))[0]
   React.useEffect (()=>{
    let API_URL = 'http://localhost:3004'
    if(!props.isLoggedIn){
      navigate('/login')
    } 
    if(userDetails && userDetails.role === 'Volunteer' ){
      navigate('/inputForm')
    }
    const username = user.username
    const password = user.password
    axios.post('/getUserNotifications',{
      username,
      password,
    }).then(res=>{
        if(res.data){
          setNotificationData(res.data)
        }
        
      })
  },[])
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElNotifications, setanchorElNotifications] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const methods = useForm({ defaultValues: {startdate: '', enddate:''} });
  const { handleSubmit, reset, control, setValue, watch } = methods;

  const isMenuOpen = Boolean(anchorEl);
  const isNotificationsOpen = Boolean(anchorElNotifications);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleNotificationsMenuOpen = (event) => {
    setanchorElNotifications(event.currentTarget);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setanchorElNotifications(null)
    handleMobileMenuClose();
  };

  const navigateTable =(data)=>{
    let startdate = data?.startdate && new Date(data.startdate).toISOString()
    let enddate = data?.enddate && new Date(data.enddate).toISOString()
    dispatch(getTable({startdate,enddate}))
    .then((res)=>{
    console.log(res)
    navigate('/table')
    })
    
  }
  const sendMail = ()=>{
    axios.post(API_URL+'/sendMail')

    
  }
  const handleNotificationsClick = (user)=>{
    
    dispatch(getUserApproval(user))
    .then((res)=>{
    console.log(res)
    navigate('/approveUser')
    })
  }
  const handleLogout =()=>{
  sessionStorage.removeItem('user')
  }


  const menuId = 'primary-search-account-menu';
  const NotificationsId = 'primary-search-account-menu-notifications';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
        <div style={{display: 'flex',flexDirection: 'column'}}>

       
      <Button onClick={handleMenuClose} href="updateUser" >My Profile</Button>
      <Button onClick={handleLogout} href="login" >Logout</Button>
      </div>
    </Menu>
  );
 
  const renderNotifications = (
    <Menu
      anchorEl={anchorElNotifications}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={NotificationsId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isNotificationsOpen}
      onClose={handleMenuClose}
    >
        <div style={{display: 'flex',flexDirection: 'column'}}>
      {
        NotificationData && NotificationData.map(x=>{
          return(
            <Button onClick={()=> handleNotificationsClick(x)} > {x.role}, {x.name}</Button>
          )
        })
      }
       
      {/* <Button onClick={handleMenuClose} href="updateUser" >User Reg Notification</Button>
      <Button onClick={handleLogout} href="login" > Samp</Button> */}
      </div>
    </Menu>
  );
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Genvi
      </Typography>
      <Divider />
      <List>
        
          <ListItem key={'home'} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={'Home'} />
            </ListItemButton>
          </ListItem>
          <ListItem key={'contact'} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={'Contact'} />
            </ListItemButton>
          </ListItem>
          
       
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            Genvi
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {/* <Button onClick={sendMail}> Home</Button> */}
              <MenuItem onClick={handleNotificationsMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <Badge badgeContent={NotificationData.length || 0} color="error">
          <NotificationsActiveTwoToneIcon/>
          </Badge>
          
        </IconButton>
               
        </MenuItem>
              {renderNotifications}
            
          </Box>
          {userDetails.role === 'Teamleader' && 
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            
          <Button key={'home'} sx={{ color: '#fff' }} href='/inputForm'>
            Submit Form
          </Button>
          {/* <Button key={'contact'} sx={{ color: '#fff' }} href='/'>
            Notifications
          </Button> */}
        
      </Box>
          }
          <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        </MenuItem>
        {renderMenu}
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
          
         </Drawer>
      </Box>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
        <Card sx={{ minWidth: 275, maxWidth:'40vw', padding: '10px' }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Welcome {userDetails.role}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          Please select a date range
        </Typography>
       
      </CardContent>
      
      <div>
     <FormInputDate name="startdate" control={control} label="Start Date" />
      <span style={{padding:'0 10px'}}>to</span>
      <FormInputDate name="enddate" control={control} label="End Date" />
      </div>
      <CardActions>
        <Button onClick= {handleSubmit(navigateTable)} size="small">Generate Report</Button>
      </CardActions>
    </Card>
    <br/>
    <br/>
        {/* <Typography>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique unde
          fugit veniam eius, perspiciatis sunt? Corporis qui ducimus quibusdam,
          aliquam dolore excepturi quae. Distinctio enim at eligendi perferendis in
          cum quibusdam sed quae, accusantium et aperiam? Quod itaque exercitationem,
          at ab sequi qui modi delectus quia corrupti alias distinctio nostrum.
          Minima ex dolor modi inventore sapiente necessitatibus aliquam fuga et. Sed
          numquam quibusdam at officia sapiente porro maxime corrupti perspiciatis
          asperiores, exercitationem eius nostrum consequuntur iure aliquam itaque,
          assumenda et! Quibusdam temporibus beatae doloremque voluptatum doloribus
          soluta accusamus porro reprehenderit eos inventore facere, fugit, molestiae
          ab officiis illo voluptates recusandae. Vel dolor nobis eius, ratione atque
          soluta, aliquam fugit qui iste architecto perspiciatis. 
        </Typography> */}
      </Box>
    </Box>
  );
}

export default connect(
 
  (state) => ({
      isLoggedIn: state.auth.isLoggedIn,
      user: state.auth.user,
      //the State.App & state.App.Items.List/Filters are reducers used as an example.
  })
  
)(HomeLanderPage);