import axios from "axios";

const API_URL = 'http://localhost:3004'

// axios.defaults.withCredentials = true;

const registerApi = (registrationParams)=>{
    return axios.post('/signup',{
        ...registrationParams
    })
}

const loginApi = (username, password) => {
    return axios
      .post("/signin", {
        username,
        password,
      })
      .then((response) => {
        if (response.data) {
          if(response.data.message?.includes('Incorrect')){
            window.loginFalse = true
          }else{
            sessionStorage.setItem("user", JSON.stringify(response.data))
          }
         
        }
        return response.data;
      })
  }

  const logout = () => {
    sessionStorage.removeItem("user");
  };
  const getTable=(dates)=>{
    return axios.post('/Table',{dates})
  }
  const getUserApproval=(user)=>{
    return axios.post('/getUserApproval',{user})
  }
  export default {
    registerApi,
    loginApi,
    logout,
    getTable,
    getUserApproval
  }