import { useForm } from "react-hook-form";
import { Paper, Button, Typography } from '@mui/material';
import { FormInputText } from './FormInputText';
import { FormInputDropdown } from './FormInputDropdown';
import { FormInputDate } from './FormInputDatepicker';
import { FormInputNumber } from './FormInputNumber';
import { FormInputDescBox } from './FormInputDescBox';
import React, { useRef } from "react";
import './commonCss.css';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import axios from "axios";
import { warning } from "@remix-run/router";
import { Navigate, useNavigate  } from 'react-router-dom';

const defaultValues = {
    pastorName: undefined,
    teamLeaderAVDetails: "",
    noOfVillages:"",
    sponsorAmount:"",
    placeName:"",
    typeofWork: "",
    dateValue: null,
    amount:'',
    feedback:'',
    noofVillages: "",
    description: ""
  };
  const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };
const InputForm = () =>{
  const [defaultVal, setdefaultVal] = React.useState(defaultValues);
  const methods = useForm({ defaultValues: defaultVal });
  const { handleSubmit, reset, control, setValue, watch } = methods;
  const [formUnfilled, setFormUnfilled] = React.useState(true)
  const [updateForm, setupdateForm] = React.useState(false);
  const navigate = useNavigate();

  let API_URL = 'http://localhost:3004'
  React.useEffect(()=>{
    const user = sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user'))[0]
   let username = user.username
   let UUID = user.UUID
   if(user.role !== 'Volunteer'){
    UUID = window.location.href.split('?',2)[1] && window.location.href.split('?',2)[1].split('%')[1] || null
    username = window.location.href.split('?',2)[1] && window.location.href.split('?',2)[1].split('%')[0] || ''
    axios.post('/checkUserRecord',{
      username, UUID
  }).then(res=>{
    if(res.status == '200' && res.data.length){
      setupdateForm(true)
      reset(res.data[0])
      // setdefaultVal(res.data[0])
    }
  })
    
  }else{
    axios.post('/checkUserRecordVolunteer',{
      username
  }).then(res=>{
    if(res.status == '200' && res.data.length ){
      
        setFormUnfilled(false)
        // alert('Response submitted')
    }
  })
  }
 
  },[])
  const onSubmit = (data) => {
    
    const user = sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user'))[0]
   
    if(data.teamLeaderAVDetails && data.typeofWork && data.description && data.dateValue){
      if(updateForm){
        axios.post('/updateTable',{
          ...data,user
      }).then(res=>{
        if(res.status == '200'){
          // setFormUnfilled(false)
          alert('Response updated')
          navigate('/table')
        }
      })
      }else{
        axios.post('/insertTable',{
          ...data,user
      }).then(res=>{
        if(res.status == '200'){
          setTimeout(()=>{
            navigate('/login')
          },1500) 
          sessionStorage.removeItem('user')
          setFormUnfilled(false)
          // alert('Response submitted')
        }
      })
      }
    }else{
      alert('Pls fill in all the mandatory fields marked with *')
    }
   
  };

  const formRef = useRef()
if(formUnfilled){
  return (

      <Paper id='form-input-mobile' ref={formRef} style={{
      display: "grid",
      gridRowGap: "20px",
      padding: "20px",
      margin: "5% 15%",
    }}>
    
    <Typography variant="h6"> Please enter the details </Typography>
    
    {/* <FormInputText name="stateName" control={control} label="State" required/> */}
    {/* <FormInputText name="name" control={control} label="Name" required/> */}
    <FormInputText name="teamLeaderAVDetails" control={control} label="Area Visit Details" required />
    {/* <FormInputText name="noOfVillages" control={control} label="No of Villages"/>
    <FormInputDropdown name="noofVillages" control={control} label="No of Villages"/> */}
    {/* <FormInputText name="sponsorAmount" control={control} label="Sponsor's Amount (Rs.)"/> */}
    {/* <FormInputText name="placeName" control={control} label="Place Name"/> */}
    <FormInputDate name="dateValue" control={control} label="Date" required />
    <FormInputNumber name="amount" control={control} label="Amount Received" />
    <FormInputText name="typeofWork" control={control} label="Type of Work" required />
    <FormInputDescBox name="description" control={control} label="Description" multiline rows={4} required/>
    <FormInputDescBox name="feedback" control={control} label="Feedback" multiline rows={2}/>
   <div style={{width:"50%", display:"flex"}}>
    <div className="col-md-2">
    <Button onClick={handleSubmit(onSubmit)} variant={"contained"} >
        
        Submit{" "}
      </Button>
      </div>
      <div className="col-md-2" style={{marginLeft:"7%"}}>
      <Button onClick={() => reset({})} variant={"outlined"}>
        
        Reset{" "}
      </Button>
      </div>
      </div>
      
    </Paper>
  )}else{
    return (
      <Paper id='form-input-mobile' ref={formRef} style={{
        display: "grid",
        gridRowGap: "20px",
        padding: "20px",
        margin: "5% 15%",
      }}>
      
      <Typography variant="h5"> Your Response is submitted </Typography>
      <Typography variant="h8"> Thanks!</Typography>
      </Paper>
    )
  }
}
export default InputForm