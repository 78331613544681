import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    TABLE,
    SET_MESSAGE,
    UserApproval
  } from "./types";
  
  import AuthService from "../services/auth.service";
  
  export const register = (registrationParams) => (dispatch) => {
    return AuthService.registerApi(registrationParams).then(
      (response) => {
        dispatch({
          type: REGISTER_SUCCESS,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return response;
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: REGISTER_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    )
  }
  
  export const login = (username, password) => (dispatch) => {
    return AuthService.loginApi(username, password).then(
      (data) => {
        if(data.message?.includes('Incorrect')){
          dispatch({
            type: LOGIN_FAIL,
          });
        }
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });
  
        return data;
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: LOGIN_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };
  
  export const logout = () => (dispatch) => {
    AuthService.logout();
  
    dispatch({
      type: LOGOUT,
    });
  };

  export const getTable = (dates) => (dispatch) => {
    return AuthService.getTable(dates).then((res)=>{
      dispatch({
        type: TABLE,
        payload: res.data
      })
      return res.data
    },(error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    })
  
    
  };

  export const getUserApproval = (user) => (dispatch) => {
    return AuthService.getUserApproval(user).then((res)=>{
      dispatch({
        type: UserApproval,
        payload: res.data
      })
      return res.data
    },(error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    })
  
    
  };