import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TextField } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from '@mui/material/styles';
const DATE_FORMAT = "dd-MM-yy";

export const FormInputDate = ({ name, control, label, ...props }) => {
  const ValidationTextField = styled(TextField)({
    '& input:valid + fieldset': {
      borderColor: 'green',
      borderWidth: 2,
    },
    '& input:invalid + fieldset': {
      borderColor: 'red',
      borderWidth: 2,
    },
    '& input:valid:focus + fieldset': {
      borderLeftWidth: 6,
      padding: '4px !important', // override inline-style
    },
  });
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        render={({ field:{ onChange, value }, fieldState:{error}, formState }) => (
          <DatePicker
          inputFormat="DD/MM/YYYY"
          helperText={'Date is mandatory'}
            {...props}
            label={label}
    value={value}
    onChange={onChange}
    renderInput={(params) => <ValidationTextField helperText={error ? error.message : null}{...params} {...props}/>}
          />
        )}
      />
      </LocalizationProvider>
    
  );
};

export default FormInputDate;
