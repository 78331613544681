import React from "react";
import { Controller } from "react-hook-form";
import { TextField,  } from "@mui/material";

// interface FormInputProps {
//     name: string;
//     control: any;
//     label: string;
//     setValue?: any;
// }

export const FormInputNumber = ({ name, label, control, ...props }) => {

    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, value },
                fieldState: { error },
                formState }) => (
                <TextField
                type='number'
                    helperText={error ? error.message : null}
                    size="small"
                    error={!!error}
                    onChange={onChange}
                    value={value}
                    fullWidth
                    label={label}
                    {...props}
                    variant="outlined" />
            )

            }
        />
    )

}