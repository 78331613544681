import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import { Paper, Button, Typography } from '@mui/material';
import { register } from "../actions/auth";
import { useNavigate } from "react-router-dom";

const API_URL = 'http://localhost:3004'

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vusername = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

const UpdateUser = () => {
  const form = useRef();
  const checkBtn = useRef();
  const navigate = useNavigate()
  // const [username, setUsername] = useState("");
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [state, setState] = useState({
    username:'',
    password:'',
    email:'',
    name: '',
    team_leader_name:'',
    place_name:'',
    state_name:'',
    role:''
  })
useEffect(()=>{
  
  const user = sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user'))[0]
  const username = user.username
  const password = user.password
  axios.post('/getUser',{
    username,
    password,
  }).then(res=>{
      if(res.data){
       setState(res.data[0])
      }
      
    })
},[])
  const { message } = useSelector(state => state.message);
  const dispatch = useDispatch();

  const onchangeEvent = (e) =>{
    let fieldName ={};
    fieldName[`${e.target.name}`] = e.target.value;
    setState({...state, ...fieldName})
  }
  // const onChangeUsername = (e) => {
  //   const username = e.target.value;
  //   setUsername(username);
  // };

  // const onChangeEmail = (e) => {
  //   const email = e.target.value;
  //   setEmail(email);
  // };

  // const onChangePassword = (e) => {
  //   const password = e.target.value;
  //   setPassword(password);
  // };

  const handleRegister = (e) => {
    e.preventDefault();

    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      axios.post('/updateUser',{
       ...state
      }).then(res=>{
          if(res.data){
           setState(res.data[0])
           alert('User details updated successfully')
           navigate('/login')
          }
          
        })
    }
  };

  return (
    <div className="col-md-4">
       <Paper style={{
      display: "grid",
      gridRowGap: "20px",
      padding: "20px",
      // margin: "10px 300px",
    }}>
      {/* <div className="card card-container" style={{padding:"20px"}}> */}
      <Typography variant="h6">Update User Details</Typography>
        {/* <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        /> */}

        <Form onSubmit={handleRegister} ref={form}>
          {!successful && (
            <div>
              <div className="form-group">
           <label htmlFor="role">Role</label>
           <Input
             type="text"
             className="form-control"
             name="role"
             value={state.role}
             disabled={true}
           />
         </div>
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <Input
                  type="text"
                  className="form-control"
                  name="username"
                  value={state.username}
                  onChange={onchangeEvent}
                  disabled={true}
                  validations={[required, vusername]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Input
                  type="text"
                  className="form-control"
                  name="email"
                  value={state.email}
                  onChange={onchangeEvent}
                  validations={[required, validEmail]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">Password</label>
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  value={state.password}
                  onChange={onchangeEvent}
                  validations={[required, vpassword]}
                />
              </div>
              <div className="form-group">
                <label htmlFor="state_name">State Name</label>
                <Input
                  type="text"
                  className="form-control"
                  name="state_name"
                  value={state.state_name}
                  onChange={onchangeEvent}
                  validations={[]}
                />
              </div>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <Input
                  type="text"
                  className="form-control"
                  name="name"
                  value={state.name}
                  onChange={onchangeEvent}
                  validations={[required]}
                />
              </div>
              {state.role == 'Volunteer' && 
              <div className="form-group">
                <label htmlFor="team_leader_name">Team Leader's Name</label>
                <Input
                  type="text"
                  className="form-control"
                  name="team_leader_name"
                  value={state.team_leader_name}
                  onChange={onchangeEvent}
                  validations={[required]}
                />
              </div>}
              {state.role !== 'Director' &&
              <div className="form-group">
                <label htmlFor="place_name">Place Name</label>
                <Input
                  type="text"
                  className="form-control"
                  name="place_name"
                  value={state.place_name}
                  onChange={onchangeEvent}
                  validations={[]}
                />
              </div>
              }
              <div className="form-group" style={{padding:"10px 0px"}}>
                <button className="btn btn-primary btn-block">Save Changes</button>
              </div>
            </div>
          )}

          {message && (
            <div className="form-group">
              <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
        {/* </div> */}
      </Paper>
    </div>
  );
};

export default UpdateUser;