import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Routes, Route, Link, useLocation, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";
import Login from "./login-components/Login";
import Register from "./login-components/Register";
import InputForm from "./form-components/InputForm"
import HomeLanderPage from "./home";
import MadeWithLove from 'react-made-with-love';
import UpdateUser from './login-components/UpdateUser';
import TableApp from './table-component/Table';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useForm } from "react-hook-form";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Navigate, useNavigate  } from 'react-router-dom';
import ApproveUser from './login-components/ApproveUser';

const defaultValues = {
  pastorName: undefined,
  teamLeaderAVDetails: "",
  noOfVillages:"",
  sponsorAmount:"",
  placeName:"",
  typeofWork: "",
  dateValue: null,
  noofVillages: "",
  description: ""
};

function App(props) {
  
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  let location = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  useEffect(() => {
    if (["/login", "/register"].includes(location.pathname)) {
      dispatch(clearMessage()); // clear message when changing location
    }
  }, [dispatch, location]);

 const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleLogout =()=>{
    sessionStorage.removeItem('user')
    }
  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
        <div style={{display: 'flex',flexDirection: 'column'}}>

       
      <Button onClick={handleMenuClose} href="updateUser" >My Profile</Button>
      <Button onClick={handleLogout} href="login" >Logout</Button>
      </div>
    </Menu>
  );

  return(
    <div className="container mt-3">
      <h1 className="genvi-title" style={{justifyContent:'center', letterSpacing : '10px',fontFamily: 'fantasy',color: 'coral'}}> Genvi </h1>
      {(window.location.pathname !== '/login' && window.location.pathname !== '/register' && window.location.pathname !== '/InputForm')  &&
        <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            Genvi
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            
              <Button key={'home'} sx={{ color: '#fff' }} href='/home'>
                Home
              </Button>
              {/* <Button key={'contact'} sx={{ color: '#fff' }} href='/'>
                Notifications
              </Button> */}
            
          </Box>
          <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        </MenuItem>
        {renderMenu}
        </Toolbar>
      </AppBar>
      
      }
        <Routes>
          <Route path="/home" element={<HomeLanderPage/>}/>
          <Route path="/" element={<Login />} />
          <Route path="/InputForm" element={<InputForm />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/updateUser" element={<UpdateUser />} />
          <Route path="/approveUser" element={<ApproveUser />} />
          <Route path="/table" element={<TableApp/>}/>
        </Routes>
        <p className='made-with-love'> <a href='https://www.risecreations.in/'>Rise Creations</a> Made By</p>
      </div>
    
  )

  
}

export default  connect(
 
  (state) => ({
      isLoggedIn: state.auth.isLoggedIn,
      user: state.auth.user,
      //the State.App & state.App.Items.List/Filters are reducers used as an example.
  })
  
)(App);
