import React from "react";

import CssBaseline from '@mui/material/CssBaseline';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableFooter from "@mui/material/TableFooter";
import TablePagination from '@mui/material/TablePagination';
import Input from "react-validation/build/input";
import HomeIcon from '@mui/icons-material/Home';
import {IconButton} from '@mui/material';
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Button from '@mui/material/Button';
// import { fade, withStyles } from "@material-ui/core/styles";
import { InputBase } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTable, useSortBy, useFilters, usePagination, useGlobalFilter, useAsyncDebounce } from "react-table";
import axios from "axios";
// import * as makeData from "./makeData";
import generateExcel from "zipcelx";
import { Navigate, useNavigate  } from 'react-router-dom';
import '../table-component/table.css'
import jsPDF from "jspdf";
import html2canvas from 'html2canvas';
import "jspdf-autotable";
import { useEffect } from "react";
import Modal from 'react-modal';
import {connect ,useDispatch} from "react-redux";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id }
}) {
  // Calculate the options for filtering
  // using the preFilteredRows

 
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
      // prevent sorting on filter click
      onClick={e => e.stopPropagation()}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// const StyledInput = useTheme(theme => ({
//   root: {
//     "label + &": {
//       marginTop: theme.spacing(3)
//     }
//   },
//   input: {
//     borderRadius: 4,
//     position: "relative",
//     backgroundColor: theme.palette.common.white,
//     border: "1px solid #ced4da",
//     fontSize: 14,
//     width: "auto",
//     padding: "10px 12px",
//     transition: theme.transitions.create(["border-color", "box-shadow"]),
//     // Use the system font instead of the default Roboto font.
//     fontFamily: [
//       "-apple-system",
//       "BlinkMacSystemFont",
//       '"Segoe UI"',
//       "Roboto",
//       '"Helvetica Neue"',
//       "Arial",
//       "sans-serif",
//       '"Apple Color Emoji"',
//       '"Segoe UI Emoji"',
//       '"Segoe UI Symbol"'
//     ].join(","),
//     "&:focus": {
//     //   boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
//       borderColor: theme.palette.primary.main
//     }
//   }
// }))(InputBase);
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
  }, 200)

  return (
      <span>
          Search:{' '}
          <input
              className="form-control"
              value={value || ""}
              onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
              }}
              placeholder={`${count} records...`}
          />
      </span>
  )
}
// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter }
}) {
  const count = preFilteredRows.length;

  return (
    <Input
      className="form-control"
      margin="none"
      variant="outlined"
      value={filterValue || ""}
      onChange={e => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      // prevent sorting on filter click
      onClick={e => e.stopPropagation()}
      placeholder={`Search ${count} records...`}
    />
  );
}

function MuiTable({ columns, data }) {
 
 
  const filterTypes = React.useMemo(
    () => ({
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter
    }),
    []
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    rows,

    // pagination stuff
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    nextPage,
    previousPage,
    gotoPage,
    setPageSize, 
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter }
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0 },
      disableMultiSort: true
    },
    useFilters,
   
    useGlobalFilter,
    useSortBy,
    usePagination
  );

const handleTableRowClick = (event, row)=>{
 
  window.openModal(row.username, row.UUID)

}  
  function getHeader(column) {
    if (column.totalVisibleHeaderCount === 1) {
      return [
        {
          value: column.Header,
          type: "string"
        }
      ];
    } else {
      const span = [...Array(column.totalVisibleHeaderCount - 1)].map(x => ({
        value: "",
        type: "string"
      }));
      return [
        {
          value: column.Header,
          type: "string"
        },
        ...span
      ];
    }
  }
  const navigate = useNavigate();
  const navigateHome =()=>{
    navigate('/home')
  }
  function getExcel() {
    const config = {
      filename: "general-ledger-Q1",
      sheet: {
        data: []
      }
    };

    const dataSet = config.sheet.data;

    // review with one level nested config
    // HEADERS
    headerGroups.forEach(headerGroup => {
      const headerRow = [];
      if (headerGroup.headers) {
        headerGroup.headers.forEach(column => {
          headerRow.push(...getHeader(column));
        });
      }

      dataSet.push(headerRow);
    });

    // FILTERED ROWS
    if (rows.length > 0) {
      rows.forEach(row => {
        const dataRow = [];

        Object.values(row.values).forEach(value =>
          dataRow.push({
            value,
            type: typeof value === "number" ? "number" : "string"
          })
        );

        dataSet.push(dataRow);
      });
    } else {
      dataSet.push([
        {
          value: "No data",
          type: "string"
        }
      ]);
    }

    return generateExcel(config);
  }

  const exportToPdf = ()=>{

    setPageSize(Number(100));
    setTimeout(()=>{
      document.querySelector("#table-pdf-root").style.padding = '25px'
      html2canvas(document.querySelector("#table-pdf-root")).then(canvas => {
        // document.body.appendChild(canvas);  // if you want see your screenshot in body.
        canvas.className = 'pdf-print'
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p','pt','a4',false);
        pdf.addImage(imgData, 'PNG', 0, 0, 600, 0, undefined, false);
        pdf.save("download.pdf"); 
        setPageSize(Number(10));
        document.querySelector("#table-pdf-root").style.padding = '0'
    },500)
   
  });
    // const unit = "pt";
    // const size = "A4"; // Use A1, A2, A3 or A4
    // const orientation = "land"; // portrait or landscape

    // const marginLeft = 40;
    // const doc = new jsPDF(orientation, unit, size);

    // doc.setFontSize(15);

    // const title = "My Awesome Report";
    // const headers = [["NAME", "PLACE NAME"]]
    // // headerGroups.forEach(headerGroup => {
    // //   const headerRow = [];
    // //   if (headerGroup.headers) {
    // //     headerGroup.headers.forEach(column => {
    // //       headerRow.push(...getHeader(column));
    // //     });
    // //   }

    // //   headers.push(headerRow);
    // // });

    // const data = [{
    
    //   firstName:"John Doe",
    //   lastName:"NJ",
    //   date:'11/01/2023',
    //   visits:' Lorem ipsum dolor sit amet consectetur ',
    //   progress:' Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique unde fugit veniam eius, perspiciatis sunt? Corporis qui ducimus quibusdam, aliquam dolore excepturi quae. Distinctio enim at eligendi perferendis in cum quibusdam sed quae, accusantium et aperiam? Quod itaque exercitationem, at ab sequi qui modi delectus quia corrupti alias distinctio nostrum.',
    //   status:"single"
    //   },
    //   {
      
    //       firstName:"Jane",
    //       lastName:"Holmdel",
    //       date:'11/01/2023',
    //       visits:' Lorem ipsum dolor sit amet consectetur ',
    //       progress:' Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique unde fugit veniam eius, perspiciatis sunt? Corporis qui ducimus quibusdam, aliquam dolore excepturi quae. Distinctio enim at eligendi perferendis in cum quibusdam sed quae, accusantium et aperiam? Quod itaque exercitationem, at ab sequi qui modi delectus quia corrupti alias distinctio nostrum.',
    //       status:"single"
    //       }];

    //       const datanew = data.map(item=>
    //         Object.keys(item).flatMap(x=>{
    //           return item[x]
    //         })
    //       )
          
    // let content = {
    //   startY: 50,
    //   head: headers,
    //   body: datanew
    // };

    // doc.text(title, marginLeft, 40);
    // doc.autoTable(content);
    // doc.save("report.pdf")
  }
  // Render the UI for your table
  return (
    <>
    <div style={{padding:'5px', display:'flex', justifyContent:'flex-end'}}>
    <Button style={{margin: '5px'}} variant="contained" onClick={getExcel}>Excel Export</Button>
    {/* <button onClick={navigateHome}>Home Page</button> */}
    <Button style={{margin: '5px'}} variant="contained" onClick={exportToPdf}>PDF Export</Button>
    {/* <Button style={{margin: '5px'}} variant="outlined"> <HomeIcon onClick={navigateHome}/></Button> */}
    </div>
    <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
            />
    <Paper id="table-pdf-root">
      
      <div style={{ overflowX: "auto" }}>
     
        <Table
          {...getTableProps()}
          size="small" // dense table sizes
        >
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => {
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  return (
                    <TableCell
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      title={null}
                      style={column.style}
                    >
                      {column.render("Header")}
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                      {/* Render the columns filter UI */}
                      {/* <div>
                        {column.canFilter ? column.render("Filter") : null}
                      </div> */}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()} hover onClick={(event) => handleTableRowClick(event, row.values)}>
                  {row.cells.map(cell => {
                    if(!cell.column.hidden){
                    return (
                      <TableCell {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </TableCell>
                    )}
                  })}
                </TableRow>
              );
            })}
          </TableBody>
          {/* <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                component="td"
                count={rows.length}
                rowsPerPage={pageSize}
                page={pageIndex}
                onChangePage={(e, p) => {
                  gotoPage(p);
                }}
                onChangeRowsPerPage={e => {
                  setPageSize(Number(e.target.value));
                }}
                ActionsComponent={() => (
                  <TablePaginationActions
                    {...{
                      previousPage,
                      nextPage,
                      gotoPage,
                      canPreviousPage,
                      canNextPage,
                      pageCount
                    }}
                  />
                )}
              />
            </TableRow>
          </TableFooter> */}
        </Table>
        {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
        <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            min={1}
            max={pageOptions.length}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      </div>
    </Paper>
    </>
  );
}

function TablePaginationActions({
  previousPage,
  nextPage,
  gotoPage,
  canPreviousPage,
  canNextPage,
  pageCount
}) {
  const handleFirstPageButtonClick = () => {
    gotoPage(0);
  };

  const handleBackButtonClick = () => {
    previousPage();
  };

  const handleNextButtonClick = () => {
    nextPage();
  };

  const handleLastPageButtonClick = () => {
    gotoPage(pageCount - 1);
  };

  return (
    <div style={{ flexShrink: 0 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={!canPreviousPage}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={!canPreviousPage}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={!canNextPage}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={!canNextPage}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
}

function TableApp(props) {

  const [tableData, setTableData] = React.useState(props.table || [])
  const [modalIsOpen, setIsOpen] = React.useState(false);
  
const navigate =  useNavigate()
const user = sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user'))[0]
   useEffect (()=>{
    let API_URL = 'http://localhost:3004'
          
    if(user.role == 'Volunteer'){
      navigate('/inputForm')
    }
    if(!props.table){
      axios.post('/Table').then(res=>{
        if(res.data){
          res.data.map(x=>x.dateValue = new Date(x.dateValue).toLocaleDateString('IN'))
          setTableData(res.data)
        }
        
      })
    }else{
      tableData.map(x=>x.dateValue = new Date(x.dateValue).toLocaleDateString('IN'))
      setTableData(tableData)
    }
   
    
   },[])

  const navigateToForm = ()=>{
    setIsOpen(false);
    window.username  && navigate(`/inputForm?${window.username}%${window.UUID}`)
  }
  const deleteRecord =()=>{
    let username = window.username
    let UUID = window.UUID
    axios.post('/DeleteRecord', {username,UUID}).then(res=>{
      if(res.status == '200'){
        setIsOpen(false);
        axios.post('/Table').then(res=>{
          if(res.data){
            res.data.map(x=>x.dateValue = new Date(x.dateValue).toLocaleDateString('IN'))
            setTableData(res.data)
          }
          
        })
      }
      
    })
  }
  window.openModal = (username, UUID)=> {
    setIsOpen(true);
    window.username = username
    window.UUID = UUID
  }

  // function afterOpenModal() {
  //   // references are now sync'd and can be accessed.
  //   subtitle.style.color = '#f00';
  // }

  function closeModal() {
    setIsOpen(false);
  }                 

   //mock data
  //  [{
    
  //   firstName:"John Doe",
  //   lastName:"NJ",
  //   date:'11/01/2023',
  //   visits:' Lorem ipsum dolor sit amet consectetur ',
  //   progress:' Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique unde fugit veniam eius, perspiciatis sunt? Corporis qui ducimus quibusdam, aliquam dolore excepturi quae. Distinctio enim at eligendi perferendis in cum quibusdam sed quae, accusantium et aperiam? Quod itaque exercitationem, at ab sequi qui modi delectus quia corrupti alias distinctio nostrum.',
  //   status:"single"
  //   },
  //   {
    
  //       firstName:"Jane",
  //       lastName:"Holmdel",
  //       date:'11/01/2023',
  //       visits:' Lorem ipsum dolor sit amet consectetur ',
  //       progress:' Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique unde fugit veniam eius, perspiciatis sunt? Corporis qui ducimus quibusdam, aliquam dolore excepturi quae. Distinctio enim at eligendi perferendis in cum quibusdam sed quae, accusantium et aperiam? Quod itaque exercitationem, at ab sequi qui modi delectus quia corrupti alias distinctio nostrum.',
  //       status:"single"
  //       }]
  // const columns = React.useMemo(
  //   () => [
  //     {
  //       Header: "Name",
  //       columns: [
  //         {
  //           Header: "First Name",
  //           accessor: "firstName",
  //         },
  //         {
  //           Header: "Last Name",
  //           accessor: "lastName",
  //         },
  //       ],
  //     },
  //     {
  //       Header: "Info",
  //       columns: [
  //         {
  //           Header: "Age",
  //           accessor: "age",
  //         },
  //         {
  //           Header: "Visits",
  //           accessor: "visits",
  //         },
  //         {
  //           Header: "Status",
  //           accessor: "status",
  //           Filter: SelectColumnFilter,
  //           filter: "includes",
  //         },
  //         {
  //           Header: "Profile Progress",
  //           accessor: "progress",
  //         },
  //       ],
  //     },
  //   ],
  //   [],
  // );
  
  const columns = React.useMemo(
    () => [
      
      {
        Header: "CDCS & CDA IN SOUTH INDIA",
        style:{
          backgroundColor : '#FFFF99'
        },
        columns:[
          {
            Header : "Tamil Nadu",
            style:{
              backgroundColor : '#00FFFF'
            },
            columns:[
              {
                Header: "User",
                accessor: "username",
                hidden: true,
                style:{
                  width:'0%',
                  backgroundColor : '#00B0F0',
                  display: 'none'
                }
                
              },
              {
                Header: "UUID",
                accessor: "UUID",
                style:{
                  width:'0%',
                  backgroundColor : '#00B0F0'
                }
                
              },
              
              {
                Header: "Name",
                accessor: "name",
                style:{
                  width:'15%',
                  backgroundColor : '#00B0F0'
                },
                show: false,
                
              },
              {
                Header: "Place Name",
                style:{
                  backgroundColor : '#00B0F0'
                },
                accessor: "teamLeaderAVDetails"
              },
              {
                Header: "Date",
                style:{
                  backgroundColor : '#00B0F0'
                },
                accessor: "dateValue"
              },
              {
                Header: "Amount Received",
                style:{
                  backgroundColor : '#00B0F0',
                  width:'10%'
                },
                accessor: "amount"
              },
              {
                Header: "Type of Work",
                style:{
                  backgroundColor : '#00B0F0',
                  width:'15%',
                },
                Filter: DefaultColumnFilter,
                filter: "includes",
                accessor: "typeofWork"
              },
            //   {
            //     Header: "Status",
            //     accessor: "status",
            //     Filter: SelectColumnFilter,
            //     filter: "includes"
            //   },
              {
                Header: "Description",
                style:{
                  backgroundColor : '#00B0F0',
                  width:'30%'
                },
                accessor: "description"
              },
              {
                Header: "Feedback",
                style:{
                  backgroundColor : '#00B0F0',
                  width:'15%',
                },
                accessor: "feedback"
              }
              
              
            ]
          }
        ]
      }
      
      
    ],
    []
  );



  return (
    <div>
      <CssBaseline />
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h4>Choose your Action</h4>
        <Button onClick={navigateToForm} variant="contained" color="success" style={{margin:'10px'}}> Update Details</Button>
        <Button onClick={deleteRecord} variant="contained" color="error"> Delete Record</Button>
        {/* <Button onClick={closeModal}> No</Button> */}
      </Modal>
      <MuiTable columns={columns} data={tableData} />
    </div>
  );
}

export default connect(
  (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    user: state.auth.user,
    table:state.auth.table
    //the State.App & state.App.Items.List/Filters are reducers used as an example.
})
)(TableApp)
