import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    TABLE,
    UserApproval
  } from "../actions/types";
  
  const user = JSON.parse(sessionStorage.getItem("user"));
  
  const initialState = user
    ? { isLoggedIn: true, user }
    : { isLoggedIn: false, user: null };
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case REGISTER_SUCCESS:
        return {
          ...state,
          isLoggedIn: false,
        };
      case REGISTER_FAIL:
        return {
          ...state,
          isLoggedIn: false,
        };
      case LOGIN_SUCCESS:
        return {
          ...state,
          isLoggedIn: true,
          user: payload.user,
        };
      case LOGIN_FAIL:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
        };
      case LOGOUT:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
        };
        case TABLE:{
          let tabData = payload.map((x)=>{
            if(x.dateValue){ 
                x.dateValue = new Date(x.dateValue).toLocaleDateString('IN')}
                return x
        })
          return {
            ...state,
            table: tabData
          };
        }
        case UserApproval:{
          console.log('payload', payload)
         return {
            ...state,
            UserApproval: payload
          };
        }
      default:
        return state;
    }
  }