import React from "react";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import { styled } from '@mui/material/styles';

export const FormInputDescBox = ({ name, label, control, ...props }) => {
    const ValidationTextField = styled(TextField)({
        '& input:valid + fieldset': {
          borderColor: 'green',
          borderWidth: 2,
        },
        '& input:invalid + fieldset': {
          borderColor: 'red',
          borderWidth: 2,
        },
        '& input:valid:focus + fieldset': {
          borderLeftWidth: 6,
          padding: '4px !important', // override inline-style
        },
      });
    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, value },
                fieldState: { error },
                formState }) => (
                <ValidationTextField
                    helperText={error ? error.message : null}
                    size="small"
                    error={!!error}
                    onChange={onChange}
                    value={value}
                    fullWidth
                    label={label}
                    {...props}
                    variant="outlined" />
            )

            }
        />
    )

}